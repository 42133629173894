import React from 'react'

import profilePic from './me.jpg'

import './Bio.scss';

const Bio = () => {
  return (
    <section className='author-bio'>
      <img
        src={profilePic}
        alt={`Amy Fairbrother`}
      />
      <p>
        <strong>Amy Fairbrother</strong> is a product-minded engineer living in Boston. When she isn't thinking about how to get code in the hands of users faster, she's probably traveling, drinking a cocktail and posting about it on <a href="https://www.instagram.com/afairb/">Instagram</a>.
      </p>
    </section>
  );
}

export default Bio
